export type ErrorFormat = {
  errorClass?: ErrorClass;
  internalMessage: string;
  safeMessage: string;
  logLevel: LOGLEVEL;
};

export enum LOGLEVEL {
  ERROR,
  WARN,
  INFO,
  SILENT,
}

const GENERIC_SAFE_MSG = "Something went wrong. Please try again.";

export const GENERIC_SAFE_FMT: ErrorFormat = {
  internalMessage: "UNKNOWN_REPLACE_WHERE_POSSIBLE",
  safeMessage: GENERIC_SAFE_MSG,
  logLevel: LOGLEVEL.ERROR,
};

const errorFormatFactory = (
  errorClass: ErrorClass,
  internalMessage: string,
  safeMessage?: string,
  logLevel?: LOGLEVEL,
): ErrorFormat => ({
  errorClass,
  internalMessage,
  safeMessage: safeMessage || internalMessage,
  logLevel: logLevel || LOGLEVEL.ERROR,
});

export enum ErrorClass {
  INCORRECT_PASSWORD,
  TRACKER_DIV,
  CALIBRATION_DIV,
  VALIDATION_DIV,
  INIT_TIMEOUT,
  CALIBRATION_TIMEOUT,
  VALIDATION_TIMEOUT,
  EXTENSION_COMMS_FAIL,
  MISSING_MODEL,
  CAMERA_PERMISSIONS,
  UPDATE_PERMISSIONS,
  VALIDATION_FAIL,
  EXT_INIT_FAIL,
}

export const KNOWN_ERRORS: Record<ErrorClass, ErrorFormat> = {
  [ErrorClass.INCORRECT_PASSWORD]: errorFormatFactory(
    ErrorClass.INCORRECT_PASSWORD,
    "Incorrect password",
  ),
  [ErrorClass.TRACKER_DIV]: errorFormatFactory(
    ErrorClass.TRACKER_DIV,
    "Unable to reference tracker div",
    GENERIC_SAFE_MSG,
  ),
  [ErrorClass.CALIBRATION_DIV]: errorFormatFactory(
    ErrorClass.CALIBRATION_DIV,
    "Unable to reference calibration div",
    GENERIC_SAFE_MSG,
  ),
  [ErrorClass.VALIDATION_DIV]: errorFormatFactory(
    ErrorClass.VALIDATION_DIV,
    "Unable to reference validation div",
    GENERIC_SAFE_MSG,
  ),
  [ErrorClass.INIT_TIMEOUT]: errorFormatFactory(
    ErrorClass.INIT_TIMEOUT,
    "broker init timed out",
    "Failed to start eyetracking software. Please try again.",
    LOGLEVEL.WARN,
  ),
  [ErrorClass.CALIBRATION_TIMEOUT]: errorFormatFactory(
    ErrorClass.CALIBRATION_TIMEOUT,
    "calibration timed out",
    "Unable to calibration. Please try again.",
    LOGLEVEL.INFO,
  ),
  [ErrorClass.VALIDATION_TIMEOUT]: errorFormatFactory(
    ErrorClass.VALIDATION_TIMEOUT,
    "validation timed out",
    "Unable to validate. Please try again.",
    LOGLEVEL.INFO,
  ),
  [ErrorClass.EXTENSION_COMMS_FAIL]: errorFormatFactory(
    ErrorClass.EXTENSION_COMMS_FAIL,
    "Unable to communicate with extension. Please try again.",
  ),
  [ErrorClass.MISSING_MODEL]: errorFormatFactory(
    ErrorClass.MISSING_MODEL,
    "Unable to send broker data to camera. Please try again.",
    GENERIC_SAFE_MSG,
  ),
  [ErrorClass.CAMERA_PERMISSIONS]: errorFormatFactory(
    ErrorClass.CAMERA_PERMISSIONS,
    "camera permissions not given",
    "Please ensure camera permissions have been granted in your browser and start again.",
    LOGLEVEL.INFO,
  ),
  [ErrorClass.UPDATE_PERMISSIONS]: errorFormatFactory(
    ErrorClass.UPDATE_PERMISSIONS,
    "extension needs update",
    "Please update the Focus Project extensions and start again.",
    LOGLEVEL.INFO,
  ),
  [ErrorClass.VALIDATION_FAIL]: errorFormatFactory(
    ErrorClass.VALIDATION_FAIL,
    "Poor validation results",
    "The validation results were not accurate enough to start a session. You will need to calibrate again.",
    LOGLEVEL.SILENT,
  ),
  [ErrorClass.EXT_INIT_FAIL]: errorFormatFactory(
    ErrorClass.EXT_INIT_FAIL,
    "Unable to init extension camera. Please try again.",
    GENERIC_SAFE_MSG,
  ),
};
