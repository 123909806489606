"use client";
import {
  menu_section_default
} from "./chunk-W342ZXYQ.mjs";
import {
  menu_item_default
} from "./chunk-GOQMGBVN.mjs";
import {
  useMenu
} from "./chunk-O3ZSXC63.mjs";

// src/menu.tsx
import { forwardRef } from "@heroui/system";
import { mergeClasses } from "@heroui/theme";
import { jsx, jsxs } from "react/jsx-runtime";
var Menu = forwardRef(function Menu2(props, ref) {
  const {
    Component,
    state,
    closeOnSelect,
    color,
    disableAnimation,
    hideSelectedIcon,
    hideEmptyContent,
    variant,
    onClose,
    topContent,
    bottomContent,
    itemClasses,
    getBaseProps,
    getListProps,
    getEmptyContentProps
  } = useMenu({ ...props, ref });
  const content = /* @__PURE__ */ jsxs(Component, { ...getListProps(), children: [
    !state.collection.size && !hideEmptyContent && /* @__PURE__ */ jsx("li", { children: /* @__PURE__ */ jsx("div", { ...getEmptyContentProps() }) }),
    [...state.collection].map((item) => {
      const itemProps = {
        closeOnSelect,
        color,
        disableAnimation,
        item,
        state,
        variant,
        onClose,
        hideSelectedIcon,
        ...item.props
      };
      const mergedItemClasses = mergeClasses(itemClasses, itemProps == null ? void 0 : itemProps.classNames);
      if (item.type === "section") {
        return /* @__PURE__ */ jsx(menu_section_default, { ...itemProps, itemClasses: mergedItemClasses }, item.key);
      }
      let menuItem = /* @__PURE__ */ jsx(menu_item_default, { ...itemProps, classNames: mergedItemClasses }, item.key);
      if (item.wrapper) {
        menuItem = item.wrapper(menuItem);
      }
      return menuItem;
    })
  ] });
  return /* @__PURE__ */ jsxs("div", { ...getBaseProps(), children: [
    topContent,
    content,
    bottomContent
  ] });
});
var menu_default = Menu;

export {
  menu_default
};
