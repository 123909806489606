// src/close.tsx
import { jsx } from "react/jsx-runtime";
var CloseIcon = (props) => {
  const { isSelected, isIndeterminate, disableAnimation, ...otherProps } = props;
  return /* @__PURE__ */ jsx(
    "svg",
    {
      "aria-hidden": "true",
      className: "fill-current",
      fill: "none",
      focusable: "false",
      height: "1em",
      role: "presentation",
      stroke: "currentColor",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeWidth: 2,
      viewBox: "0 0 24 24",
      width: "1em",
      ...otherProps,
      children: /* @__PURE__ */ jsx("path", { d: "M18 6L6 18M6 6l12 12" })
    }
  );
};

export {
  CloseIcon
};
