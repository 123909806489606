import React from "react";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/solid";

interface InstallationIconProps {
  id: number;
  step: number;
  done: boolean;
  cameraState: PermissionState;
}

const InstallationIcon: React.FC<InstallationIconProps> = ({
  id,
  step,
  done,
  cameraState,
}) => {
  const baseIconProps = "w-6 h-6 inline-block";

  if (id < step || (id === step && done)) {
    return <CheckCircleIcon className={`${baseIconProps} text-green-500`} />;
  }

  if (id === step) {
    if (step === 3) {
      const iconMap = {
        granted: (
          <CheckCircleIcon className={`${baseIconProps} text-green-500`} />
        ),
        denied: (
          <ExclamationCircleIcon className={`${baseIconProps} text-red-500`} />
        ),
        prompt: (
          <ExclamationCircleIcon
            className={`${baseIconProps} text-yellow-500`}
          />
        ),
      };
      return (
        iconMap[cameraState] || (
          <ExclamationCircleIcon
            className={`${baseIconProps} text-yellow-500`}
          />
        )
      );
    }

    if (step === 2) {
      return (
        <ExclamationCircleIcon className={`${baseIconProps} text-red-500`} />
      );
    }
    return (
      <ExclamationCircleIcon className={`${baseIconProps} text-yellow-500`} />
    );
  }

  return <ExclamationCircleIcon className={`${baseIconProps} text-gray-400`} />;
};

export default InstallationIcon;
