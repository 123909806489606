import { ErrorClass } from "@/utils/errorFormat";
import extensionSendMessage from "@/utils/extensionCom";
import { PanelMessages } from "@lumen-developer/lumen-common-js/esm/panel/messages";
import { PanelTypes } from "@lumen-developer/lumen-common-js/esm/panel/types";
import {
  Dispatch,
  RefObject,
  SetStateAction,
  useCallback,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { setTmpSessionId } from "@/store/reducers/session";
import { useSessionErrorContext } from "@/contexts/sessionErrorContext";
import {
  INIT_RESOLVE_EVENT,
  LRExtensionBroker,
} from "@lumen-developer/lumen-common-js/esm/brokers";
import { SessionRoute } from "@/types/session";
import { AppDispatch } from "@/store/store";
import { getSessionRequest } from "../api";

interface UseHandleSessionProps {
  panelist: string;
  maxAcc: number;
  maxPrec: number;
  brokerVersionConfig: any;
  viewabilityVersionConfig: any;
  dispatch: AppDispatch;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  cameraState: PermissionState;
  trackerRef: RefObject<HTMLDivElement>;
  errorHandle: (error: unknown, action?: () => void) => void;
  broker: LRExtensionBroker;
}

export interface IHandleSessionState {
  hasExtension: boolean;
  hasError: boolean;
  hasExtensionCamera: boolean;
  requiresRestart: boolean;
}

interface IUseHandleSession extends IHandleSessionState {
  cont: () => Promise<void>;
  setState: Dispatch<SetStateAction<IHandleSessionState>>;
}

const useHandleSession = (params: UseHandleSessionProps): IUseHandleSession => {
  const {
    panelist,
    maxAcc,
    maxPrec,
    brokerVersionConfig,
    viewabilityVersionConfig,
    dispatch,
    setIsLoading,
    cameraState,
    trackerRef,
    errorHandle,
    broker,
  } = params;
  const navigate = useNavigate();
  const errorActionRef = useRef<() => void>(() => {
    window.location.reload();
  });
  const [state, setState] = useState({
    hasExtension: false,
    hasError: false,
    hasExtensionCamera: false,
    requiresRestart: false,
  });
  const { getError } = useSessionErrorContext();

  const initBroker = useCallback(async () => {
    const div = trackerRef.current;
    if (!div) {
      const message = getError(ErrorClass.TRACKER_DIV).internalMessage;
      errorHandle(message);
    }
    try {
      await broker.init(30000, div, {
        resolveEvent: INIT_RESOLVE_EVENT.GAZE_DETECTOR_INIT,
      });
    } catch (e) {
      setState((prev) => ({
        ...prev,
        hasExtension: true,
        hasExtensionCamera: true,
        hasError: true,
        requiresRestart: cameraState === "denied",
      }));
    }
  }, [broker, errorHandle, getError, trackerRef, setState, cameraState]);

  const cont = useCallback(async () => {
    const handleError = (error) => {
      switch (error) {
        case ErrorClass.EXTENSION_COMMS_FAIL:
          setState((prev) => ({
            ...prev,
            hasExtension: false,
            hasExtensionCamera: true,
            hasError: true,
          }));
          break;
        case ErrorClass.CAMERA_PERMISSIONS:
          setState((prev) => ({
            ...prev,
            hasExtension: true,
            hasExtensionCamera: true,
            hasError: true,
          }));
          break;
        case ErrorClass.EXT_INIT_FAIL:
          setState((prev) => ({
            ...prev,
            hasExtension: true,
            hasExtensionCamera: false,
            hasError: true,
          }));
          break;
        default:
          errorHandle(error, errorActionRef.current);
          break;
      }
    };

    setIsLoading(true);
    try {
      const m: PanelMessages.ExternalMessage = {
        type: PanelMessages.ExternalMessageType.CheckPermissions,
      };
      const perms: boolean | PanelTypes.PermissionsResponse =
        await extensionSendMessage(m);
      // for backwards compat, can be removed after extension version release
      if (typeof perms === "boolean") {
        if (!perms) {
          /* eslint-disable-next-line @typescript-eslint/no-throw-literal */
          throw ErrorClass.CAMERA_PERMISSIONS;
        }
      } else {
        if (!perms.camera) {
          /* eslint-disable-next-line @typescript-eslint/no-throw-literal */
          throw ErrorClass.EXT_INIT_FAIL;
        }
        if (!perms.noUpdate) {
          errorActionRef.current = () => {
            window
              .open(
                `chrome://extensions/?id=${process.env.EXTENSION_ID}`,
                "_blank",
              )
              ?.focus();
            window.location.reload();
          };
          /* eslint-disable-next-line @typescript-eslint/no-throw-literal */
          throw ErrorClass.UPDATE_PERMISSIONS;
        }
      }

      const res = await getSessionRequest(
        panelist,
        maxAcc,
        maxPrec,
        brokerVersionConfig,
        viewabilityVersionConfig,
      );
      dispatch(setTmpSessionId(res.tmpSessionId));

      if (!broker.state.initialised) {
        await initBroker();
      }

      if (cameraState === "granted") {
        navigate(SessionRoute.LIGHTING);
      } else {
        setState((prev) => ({
          ...prev,
          hasExtension: true,
          hasExtensionCamera: true,
          hasError: true,
          requiresRestart: cameraState === "denied",
        }));
      }
    } catch (e) {
      handleError(e);
    }
    setIsLoading(false);
  }, [
    panelist,
    maxAcc,
    maxPrec,
    brokerVersionConfig,
    viewabilityVersionConfig,
    dispatch,
    navigate,
    broker.state.initialised,
    cameraState,
    errorHandle,
    initBroker,
    setIsLoading,
    setState,
  ]);

  return { cont, ...state, setState };
};

export default useHandleSession;
