import React, { useRef, useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Button } from "@heroui/react";

import { SessionRoute, SessionSectionContext } from "@/types/session";
import Header from "@/components/header/header";

const SessionLighting = () => {
  const {
    broker: { clearQualityTimeout, getEyeIlluminationQuality },
    setStatus,
    status: { lighting },
  } = useOutletContext<SessionSectionContext>();
  const navigate = useNavigate();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const cont = () => {
    clearQualityTimeout();
    navigate(SessionRoute.POSITION);
  };

  const normalize = (value: number) => {
    const mx = Math.log(value - 0) / Math.log(100 - 0);
    const preshiftNormalized = mx * (100 - 70);
    const shiftedNormalized = preshiftNormalized + 70;

    return Math.round(shiftedNormalized);
  };

  useEffect(() => {
    getEyeIlluminationQuality((res) => {
      setStatus((value) => ({ ...value, lighting: normalize(res * 100) }));
    });
  }, [getEyeIlluminationQuality, setStatus]);

  return (
    <>
      <Header size="h2">Step 1: Lighting</Header>
      <div className="flex flex-col gap-5 mb-8">
        <p>So we can see your eyes, being in a well lit room is best.</p>
        <p>To continue, a lighting score of 80 or more is required.</p>
      </div>
      <Button
        color="secondary"
        onPress={cont}
        isDisabled={lighting < 80}
        ref={buttonRef}
      >
        Continue
      </Button>
    </>
  );
};

export default SessionLighting;
