import React from "react";
import { Accordion, AccordionItem } from "@heroui/react";

import { useAppSelector } from "@/hooks/store";
import Loading from "@/components/loading/loading";
import { useFAQs } from "../api";
import ErrorMessage from "./errorMessage";

const FAQs = () => {
  const { panelistId, accessCode, loggedIn } = useAppSelector(
    (state) => state.auth,
  );

  const [{ data, loading }] = useFAQs(accessCode, panelistId, loggedIn);

  if (loading) return <Loading />;
  if (!data?.success) return <ErrorMessage />;

  return (
    <section className="mb-10">
      <h1 className="font-semibold mb-5 text-2xl">
        Frequently Asked Questions
      </h1>

      <Accordion
        variant="bordered"
        className="font-semibold"
        selectionMode="multiple"
        defaultSelectedKeys="all"
      >
        {data.faq_list?.map(({ question_category, faq_list, position }) => (
          <AccordionItem
            key={position}
            aria-label={`Accordion ${question_category}`}
            title={question_category}
          >
            <div className="font-normal">
              <Accordion variant="light">
                {faq_list.map(({ question, answer }) => (
                  <AccordionItem
                    key={question}
                    aria-label={`Accordion 2 ${question}`}
                    title={question}
                  >
                    <div className="flex flex-col gap-4 whitespace-pre-wrap">
                      {answer}
                    </div>
                  </AccordionItem>
                ))}
              </Accordion>
            </div>
          </AccordionItem>
        ))}
      </Accordion>
    </section>
  );
};

export default FAQs;
