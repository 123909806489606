import { Dispatch, SetStateAction, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { IHandleSessionState } from "./useHandleSession";

interface UseSessionParamsProps {
  setState: Dispatch<SetStateAction<IHandleSessionState>>;
  cameraStateIsLoading: boolean;
  cameraState: PermissionState;
  cont: React.MutableRefObject<() => Promise<void>>;
}

const useSessionParams = ({
  setState,
  cameraStateIsLoading,
  cameraState,
  cont,
}: UseSessionParamsProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const processParams = useCallback(() => {
    if (cameraStateIsLoading) return;

    const autostart = searchParams.get("autostart");
    const browserCamera = searchParams.get("browserCamera");
    const extensionCamera = searchParams.get("extensionCamera");
    if (extensionCamera === "denied") {
      setState((prev) => ({
        ...prev,
        hasExtension: true,
        hasExtensionCamera: false,
        hasError: true,
        requiresRestart: true,
      }));
    }

    if (autostart && cameraState === "denied") {
      setSearchParams();
    }
    if (browserCamera === "false") {
      setState((prev) => ({
        ...prev,
        hasExtension: true,
        hasExtensionCamera: true,
        hasError: true,
        requiresRestart: true,
      }));
    }
    if (browserCamera === "true" && cameraState !== "granted") return;

    if (autostart === "true") {
      cont.current();
    }
  }, [
    searchParams,
    cameraState,
    cameraStateIsLoading,
    setState,
    setSearchParams,
    cont,
  ]);

  return { processParams };
};

export default useSessionParams;
