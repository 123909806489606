import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";

import { SessionExternalContext } from "@/types/session";
import Loading from "@/components/loading/loading";
import Header from "@/components/header/header";
import PromptList from "@/components/promptList/promptList";
import { IDomain, usePromptList } from "../../../api";

const DEFAULT_DOMAIN: IDomain = {
  domain_name: "Google",
  url: "https://google.com/",
};

const SessionExternal = () => {
  const { panelistId, accessCode } = useOutletContext<SessionExternalContext>();
  const [{ data, loading }] = usePromptList(accessCode, panelistId);
  const [availableDomains, setAvailableDomains] = useState<IDomain[]>([]);

  useEffect(() => {
    if (!data?.success || loading) return;
    setAvailableDomains(
      data.domain_list?.length ? data.domain_list : [DEFAULT_DOMAIN],
    );
  }, [loading, data]);

  if (!availableDomains?.length) return <Loading showLabel={false} />;

  const hasMultipleDomains = availableDomains.length > 1;
  const startSessionOptions = hasMultipleDomains
    ? "Select a website from the suggested options below to start your session."
    : `Click start session below and you will be taken to ${availableDomains[0].domain_name} to start you session`;

  return (
    <>
      <Header size="h2"> How to earn points in your session?</Header>
      <ol className="mb-8 list-decimal list-inside">
        <li>{startSessionOptions}</li>
        <li>
          For the duration of the session browse the internet as you usually
          would
        </li>
        <li>
          After 10 minutes you will be automatically redirected in a new tab to
          a validation screen
        </li>
        <li>Complete a successful validation in order to secure your points</li>
      </ol>
      <p className="mb-8">Your session will last 10 minutes.</p>
      <PromptList {...{ availableDomains, hasMultipleDomains }} />
    </>
  );
};

export default SessionExternal;
