import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Link } from "@heroui/react";

import Header from "@/components/header/header";

const SessionCompletion = () => {
  const navigate = useNavigate();

  return (
    <>
      <Header size="h2">Session complete</Header>
      <div className="flex flex-col gap-5 mb-8">
        <p>Thank you for participating and completing a session.</p>
        <p>
          You can view your session history in the{" "}
          <Link href="/rewards">rewards page</Link> or alternatively click the
          button to start another session.
        </p>
      </div>

      <Button color="secondary" onPress={() => navigate("/")}>
        Start another session
      </Button>
    </>
  );
};

export default SessionCompletion;
