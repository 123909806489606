"use client";
import {
  ProviderContext
} from "./chunk-Q3W45BN5.mjs";

// src/provider.tsx
import { I18nProvider } from "@react-aria/i18n";
import { RouterProvider } from "@react-aria/utils";
import { OverlayProvider } from "@react-aria/overlays";
import { useMemo } from "react";
import { MotionConfig, MotionGlobalConfig } from "framer-motion";
import { jsx } from "react/jsx-runtime";
var HeroUIProvider = ({
  children,
  navigate,
  disableAnimation,
  useHref,
  disableRipple = false,
  skipFramerMotionAnimations = disableAnimation,
  reducedMotion = "never",
  validationBehavior,
  locale = "en-US",
  labelPlacement,
  // if minDate / maxDate are not specified in `defaultDates`
  // then they will be set in `use-date-input.ts` or `use-calendar-base.ts`
  defaultDates,
  createCalendar,
  spinnerVariant,
  ...otherProps
}) => {
  let contents = children;
  if (navigate) {
    contents = /* @__PURE__ */ jsx(RouterProvider, { navigate, useHref, children: contents });
  }
  const context = useMemo(() => {
    if (disableAnimation && skipFramerMotionAnimations) {
      MotionGlobalConfig.skipAnimations = true;
    }
    return {
      createCalendar,
      defaultDates,
      disableAnimation,
      disableRipple,
      validationBehavior,
      labelPlacement,
      spinnerVariant
    };
  }, [
    createCalendar,
    defaultDates == null ? void 0 : defaultDates.maxDate,
    defaultDates == null ? void 0 : defaultDates.minDate,
    disableAnimation,
    disableRipple,
    validationBehavior,
    labelPlacement,
    spinnerVariant
  ]);
  return /* @__PURE__ */ jsx(ProviderContext, { value: context, children: /* @__PURE__ */ jsx(I18nProvider, { locale, children: /* @__PURE__ */ jsx(MotionConfig, { reducedMotion, children: /* @__PURE__ */ jsx(OverlayProvider, { ...otherProps, children: contents }) }) }) });
};

export {
  HeroUIProvider
};
