import { useState, useEffect } from "react";

interface UseCameraPermissionProps {
  cameraState: PermissionState | null;
  cameraStateIsLoading: boolean;
  cameraStateError: boolean;
}

const useCameraPermission = (): UseCameraPermissionProps => {
  const [cameraState, setCameraState] = useState<PermissionState | null>(null);
  const [cameraStateIsLoading, setCameraStateIsLoading] = useState(true);
  const [cameraStateError, setCameraStateError] = useState(false);

  useEffect(() => {
    const getCameraPermission = async () => {
      try {
        const permissionStatus = await navigator.permissions.query({
          name: "camera" as PermissionName,
        });

        setCameraState(permissionStatus.state);

        permissionStatus.onchange = () => {
          setCameraState(permissionStatus.state);
        };
      } catch (error) {
        setCameraStateError(true);
      } finally {
        setCameraStateIsLoading(false);
      }
    };

    getCameraPermission();
  }, []);

  return { cameraState, cameraStateIsLoading, cameraStateError };
};

export default useCameraPermission;
