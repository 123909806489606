"use client";
import {
  useProviderContext
} from "./chunk-Q3W45BN5.mjs";

// src/hooks/use-label-placement.ts
import { useMemo } from "react";
function useLabelPlacement(props) {
  const globalContext = useProviderContext();
  const globalLabelPlacement = globalContext == null ? void 0 : globalContext.labelPlacement;
  return useMemo(() => {
    var _a, _b;
    const labelPlacement = (_b = (_a = props.labelPlacement) != null ? _a : globalLabelPlacement) != null ? _b : "inside";
    if (labelPlacement === "inside" && !props.label) {
      return "outside";
    }
    return labelPlacement;
  }, [props.labelPlacement, globalLabelPlacement, props.label]);
}

export {
  useLabelPlacement
};
