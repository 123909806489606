// src/demi/react18/getInertValue.ts
var getInertValue = (v) => {
  return v ? "" : void 0;
};

// src/common/assertion.ts
var __DEV__ = process.env.NODE_ENV !== "production";
var __TEST__ = process.env.NODE_ENV === "test";
function isArray(value) {
  return Array.isArray(value);
}
function isEmptyArray(value) {
  return isArray(value) && value.length === 0;
}
function isObject(value) {
  const type = typeof value;
  return value != null && (type === "object" || type === "function") && !isArray(value);
}
function isEmptyObject(value) {
  return isObject(value) && Object.keys(value).length === 0;
}
function isEmpty(value) {
  if (isArray(value)) return isEmptyArray(value);
  if (isObject(value)) return isEmptyObject(value);
  if (value == null || value === "") return true;
  return false;
}
function isFunction(value) {
  return typeof value === "function";
}
var dataAttr = (condition) => condition ? "true" : void 0;
var isNumeric = (value) => value != null && parseInt(value.toString(), 10) > 0;

// src/common/clsx.ts
function toVal(mix) {
  var k, y, str = "";
  if (typeof mix === "string" || typeof mix === "number") {
    str += mix;
  } else if (typeof mix === "object") {
    if (Array.isArray(mix)) {
      for (k = 0; k < mix.length; k++) {
        if (mix[k]) {
          if (y = toVal(mix[k])) {
            str && (str += " ");
            str += y;
          }
        }
      }
    } else {
      for (k in mix) {
        if (mix[k]) {
          str && (str += " ");
          str += k;
        }
      }
    }
  }
  return str;
}
function clsx(...args) {
  var i = 0, tmp, x, str = "";
  while (i < args.length) {
    if (tmp = args[i++]) {
      if (x = toVal(tmp)) {
        str && (str += " ");
        str += x;
      }
    }
  }
  return str;
}

// src/common/object.ts
var renameProp = (oldProp, newProp, { [oldProp]: old, ...others }) => ({
  [newProp]: old,
  ...others
});
var copyObject = (obj) => {
  if (!isObject(obj)) return obj;
  if (obj instanceof Array) return [...obj];
  return { ...obj };
};
var omitObject = (obj, omitKeys) => {
  if (!isObject(obj)) return obj;
  if (obj instanceof Array) return [...obj];
  const newObj = { ...obj };
  omitKeys.forEach((key) => newObj[key] && delete newObj[key]);
  return newObj;
};
var cleanObject = (obj) => {
  if (!isObject(obj)) return obj;
  if (obj instanceof Array) return [...obj];
  const newObj = { ...obj };
  Object.keys(newObj).forEach((key) => {
    if (newObj[key] === void 0 || newObj[key] === null) {
      delete newObj[key];
    }
  });
  return newObj;
};
var cleanObjectKeys = (obj, keys = []) => {
  if (!isObject(obj)) return obj;
  if (obj instanceof Array) return [...obj];
  const newObj = { ...obj };
  keys.forEach((key) => {
    if (newObj[key]) {
      delete newObj[key];
    }
  });
  return newObj;
};
var getKeyValue = (obj, key) => {
  if (!isObject(obj)) return obj;
  if (obj instanceof Array) return [...obj];
  return obj[key];
};
var getProp = (obj, path, fallback, index) => {
  const key = typeof path === "string" ? path.split(".") : [path];
  for (index = 0; index < key.length; index += 1) {
    if (!obj) break;
    obj = obj[key[index]];
  }
  return obj === void 0 ? fallback : obj;
};
var arrayToObject = (arr) => {
  if (!arr.length || !Array.isArray(arr)) return {};
  return arr.reduce((acc, item) => {
    return { ...acc, ...item };
  }, {});
};
function compact(object) {
  const clone = Object.assign({}, object);
  for (let key in clone) {
    if (clone[key] === void 0) delete clone[key];
  }
  return clone;
}

// src/common/text.ts
var safeText = (text) => {
  if ((text == null ? void 0 : text.length) <= 4) return text;
  return text == null ? void 0 : text.slice(0, 3);
};
var safeAriaLabel = (...texts) => {
  let ariaLabel = " ";
  for (const text of texts) {
    if (typeof text === "string" && text.length > 0) {
      ariaLabel = text;
      break;
    }
  }
  return ariaLabel;
};

// src/common/dimensions.ts
var getMargin = (num) => {
  return `calc(${num * 15.25}pt + 1px * ${num - 1})`;
};

// src/common/functions.ts
var capitalize = (s) => {
  return s ? s.charAt(0).toUpperCase() + s.slice(1).toLowerCase() : "";
};
function callAllHandlers(...fns) {
  return function func(event) {
    fns.some((fn) => {
      fn == null ? void 0 : fn(event);
      return event == null ? void 0 : event.defaultPrevented;
    });
  };
}
function callAll(...fns) {
  return function mergedFn(arg) {
    fns.forEach((fn) => {
      fn == null ? void 0 : fn(arg);
    });
  };
}
function extractProperty(key, defaultValue, ...objs) {
  let result = defaultValue;
  for (const obj of objs) {
    if (obj && key in obj && !!obj[key]) {
      result = obj[key];
    }
  }
  return result;
}
function getUniqueID(prefix) {
  return `${prefix}-${Math.floor(Math.random() * 1e6)}`;
}
function removeEvents(input) {
  for (const key in input) {
    if (key.startsWith("on")) {
      delete input[key];
    }
  }
  return input;
}
function objectToDeps(obj) {
  if (!obj || typeof obj !== "object") {
    return "";
  }
  try {
    return JSON.stringify(obj);
  } catch (e) {
    return "";
  }
}
function debounce(func, waitMilliseconds = 0) {
  let timeout;
  return function(...args) {
    const later = () => {
      timeout = void 0;
      func.apply(this, args);
    };
    if (timeout !== void 0) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(later, waitMilliseconds);
  };
}
function uniqBy(arr, iteratee) {
  if (typeof iteratee === "string") {
    iteratee = (item) => item[iteratee];
  }
  return arr.filter((x, i, self) => i === self.findIndex((y) => iteratee(x) === iteratee(y)));
}
var omit = (obj, keys) => {
  const res = Object.assign({}, obj);
  keys.forEach((key) => {
    delete res[key];
  });
  return res;
};
var kebabCase = (s) => {
  return s.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();
};
var mapKeys = (obj, iteratee) => {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [iteratee(value, key), value])
  );
};
var get = (object, path, defaultValue) => {
  const keys = Array.isArray(path) ? path : path.replace(/\[(\d+)\]/g, ".$1").split(".");
  let res = object;
  for (const key of keys) {
    res = res == null ? void 0 : res[key];
    if (res === void 0) {
      return defaultValue;
    }
  }
  return res;
};
var intersectionBy = (...args) => {
  if (args.length < 2) {
    throw new Error("intersectionBy requires at least two arrays and an iteratee");
  }
  const iteratee = args[args.length - 1];
  const arrays = args.slice(0, -1);
  if (arrays.length === 0) {
    return [];
  }
  const getIterateeValue = (item) => {
    if (typeof iteratee === "function") {
      return iteratee(item);
    } else if (typeof iteratee === "string") {
      return item[iteratee];
    } else {
      throw new Error("Iteratee must be a function or a string key of the array elements");
    }
  };
  const [first, ...rest] = arrays;
  const transformedFirst = first.map((item) => getIterateeValue(item));
  const transformedSets = rest.map(
    (array) => new Set(array.map((item) => getIterateeValue(item)))
  );
  const res = [];
  const seen = /* @__PURE__ */ new Set();
  for (let i = 0; i < first.length; i++) {
    const item = first[i];
    const transformed = transformedFirst[i];
    if (seen.has(transformed)) {
      continue;
    }
    const existsInAll = transformedSets.every((set) => set.has(transformed));
    if (existsInAll) {
      res.push(item);
      seen.add(transformed);
    }
  }
  return res;
};

// src/common/numbers.ts
function range(start, end) {
  const length = end - start + 1;
  return Array.from({ length }, (_, index) => index + start);
}
function clamp(value, min, max) {
  return Math.min(Math.max(value, min), max);
}
function clampPercentage(value, max = 100) {
  return Math.min(Math.max(value, 0), max);
}

// src/common/console.ts
var warningStack = {};
function warn(message, component, ...args) {
  const tag = component ? ` [${component}]` : " ";
  const log = `[Hero UI]${tag}: ${message}`;
  if (typeof console === "undefined") return;
  if (warningStack[log]) return;
  warningStack[log] = true;
  if (process.env.NODE_ENV !== "production") {
    return console.warn(log, args);
  }
}

// src/common/dates.ts
function getGregorianYearOffset(identifier) {
  switch (identifier) {
    case "buddhist":
      return 543;
    case "ethiopic":
    case "ethioaa":
      return -8;
    case "coptic":
      return -284;
    case "hebrew":
      return 3760;
    case "indian":
      return -78;
    case "islamic-civil":
    case "islamic-tbla":
    case "islamic-umalqura":
      return -579;
    case "persian":
      return -600;
    case "roc":
    case "japanese":
    case "gregory":
    default:
      return 0;
  }
}

// src/common/regex.ts
var isPatternNumeric = (pattern) => {
  const numericPattern = /(^|\W)[0-9](\W|$)/;
  return numericPattern.test(pattern) && !/[^\d\^$\[\]\(\)\*\+\-\.\|]/.test(pattern);
};
export {
  __DEV__,
  __TEST__,
  arrayToObject,
  callAll,
  callAllHandlers,
  capitalize,
  clamp,
  clampPercentage,
  cleanObject,
  cleanObjectKeys,
  clsx,
  compact,
  copyObject,
  dataAttr,
  debounce,
  extractProperty,
  get,
  getGregorianYearOffset,
  getInertValue,
  getKeyValue,
  getMargin,
  getProp,
  getUniqueID,
  intersectionBy,
  isArray,
  isEmpty,
  isEmptyArray,
  isEmptyObject,
  isFunction,
  isNumeric,
  isObject,
  isPatternNumeric,
  kebabCase,
  mapKeys,
  objectToDeps,
  omit,
  omitObject,
  range,
  removeEvents,
  renameProp,
  safeAriaLabel,
  safeText,
  uniqBy,
  warn
};
