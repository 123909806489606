import React, { useEffect, useState } from "react";
import { Button, Link } from "@heroui/react";
import { useForm, SubmitHandler } from "react-hook-form";

import { useAppDispatch } from "@/hooks/store";
import { logIn } from "@/store/reducers/auth";
import { loginRequest } from "@/api/index";
import { disabledTimer } from "@/utils/helpers";
import Input from "../input/input";
import ExternalHeader from "../header/externalHeader";

interface ILoginForm {
  email: string;
  password: string;
}

const Login = () => {
  const dispatch = useAppDispatch();
  const [isDisabled, setIsDisabled] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setFocus,
    setError,
  } = useForm<ILoginForm>();

  const sendLogIn: SubmitHandler<ILoginForm> = async ({ email, password }) => {
    disabledTimer(setIsDisabled);

    try {
      const res = await loginRequest(email, password);
      dispatch(logIn(res));
    } catch {
      setError("password", { message: "email or password invalid" });
      setFocus("password");
    }
  };

  useEffect(() => {
    setFocus("email");
  }, [setFocus]);

  return (
    <form onSubmit={handleSubmit(sendLogIn)}>
      <ExternalHeader title="Sign In" />
      <Input
        label="Email Address"
        type="text"
        autoComplete="email"
        register={register("email", {
          required: "Please enter your email address",
          pattern: {
            value: /^\S+@\S+$/i,
            message: "Please enter a valid email address",
          },
        })}
        errorMessage={errors?.email?.message}
      />
      <Input
        label="Password"
        type="password"
        register={register("password", {
          required: "Please enter your password",
        })}
        errorMessage={errors?.password?.message}
      />
      <div className="flex items-center justify-content gap-9">
        <Button color="secondary" type="submit" isDisabled={isDisabled}>
          Log In
        </Button>
        <Link href="/account-admin/forgot-password">Forgot Password?</Link>
      </div>
    </form>
  );
};

export default Login;
