// src/index.ts
import { useRef, useState, useEffect, useCallback } from "react";
import { useIsHydrated } from "@heroui/react-utils";
import { useSafeLayoutEffect } from "@heroui/use-safe-layout-effect";
function useImage(props = {}) {
  const { onLoad, onError, ignoreFallback, src, crossOrigin, srcSet, sizes, loading } = props;
  const isHydrated = useIsHydrated();
  const imageRef = useRef(isHydrated ? new Image() : null);
  const [status, setStatus] = useState("pending");
  useEffect(() => {
    if (!imageRef.current) return;
    imageRef.current.onload = (event) => {
      flush();
      setStatus("loaded");
      onLoad == null ? void 0 : onLoad(event);
    };
    imageRef.current.onerror = (error) => {
      flush();
      setStatus("failed");
      onError == null ? void 0 : onError(error);
    };
  }, [imageRef.current]);
  const flush = () => {
    if (imageRef.current) {
      imageRef.current.onload = null;
      imageRef.current.onerror = null;
      imageRef.current = null;
    }
  };
  const load = useCallback(() => {
    if (!src) return "pending";
    if (ignoreFallback) return "loaded";
    const img = new Image();
    img.src = src;
    if (crossOrigin) img.crossOrigin = crossOrigin;
    if (srcSet) img.srcset = srcSet;
    if (sizes) img.sizes = sizes;
    if (loading) img.loading = loading;
    imageRef.current = img;
    if (img.complete && img.naturalWidth) {
      return "loaded";
    }
    return "loading";
  }, [src, crossOrigin, srcSet, sizes, onLoad, onError, loading]);
  useSafeLayoutEffect(() => {
    if (isHydrated) {
      setStatus(load());
    }
  }, [isHydrated, load]);
  return ignoreFallback ? "loaded" : status;
}
var shouldShowFallbackImage = (status, fallbackStrategy) => status !== "loaded" && fallbackStrategy === "beforeLoadOrError" || status === "failed" && fallbackStrategy === "onError";
export {
  shouldShowFallbackImage,
  useImage
};
