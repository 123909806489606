import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";

import Input from "@/components/input/input";
import { Button } from "@heroui/react";
import { useAlertContext } from "@/contexts/alertContext";
import ExternalHeader from "@/components/header/externalHeader";
import { disabledTimer } from "@/utils/helpers";
import { forgotPassword } from "../api";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setFocus,
  } = useForm<{ email: string }>();
  const { addAlert } = useAlertContext();
  const [isDisabled, setIsDisabled] = useState(false);

  const sendResetPasswordLink: SubmitHandler<{ email: string }> = async ({
    email,
  }) => {
    disabledTimer(setIsDisabled);
    try {
      await forgotPassword(email);
      addAlert({
        message: "Please check your email",
        type: "success",
        timeout: 5,
      });
    } catch (e) {
      addAlert({
        message: e?.message,
        type: "error",
        timeout: 5,
      });
    }
  };

  useEffect(() => {
    setFocus("email");
  }, [setFocus]);

  return (
    <form onSubmit={handleSubmit(sendResetPasswordLink)}>
      <ExternalHeader title="Password Reset" />
      <section className="my-4">
        <p>
          Enter your email to receive a password reset link to that address in a
          few minutes. Remember to check the spam folder.
        </p>
      </section>
      <Input
        label="Email Address"
        type="text"
        autoComplete="email"
        register={register("email", {
          required: "Please enter your email address",
          pattern: {
            value: /^\S+@\S+$/i,
            message: "Please enter a valid email address",
          },
        })}
        errorMessage={errors?.email?.message}
      />
      <div className="flex items-center justify-content gap-9">
        <Button type="submit" color="secondary" isDisabled={isDisabled}>
          Send request
        </Button>
        <Button
          type="button"
          variant="bordered"
          onPress={() => {
            setTimeout(() => {
              navigate("/");
            }, 200);
          }}
        >
          Cancel
        </Button>
      </div>
    </form>
  );
};

export default ForgotPassword;
