import { Button, Select, SelectItem } from "@heroui/react";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import Input from "@/components/input/input";
import { useAppSelector } from "@/hooks/store";
import { useAlertContext } from "@/contexts/alertContext";
import { disabledTimer } from "@/utils/helpers";
import ErrorMessage from "@/pages/errorMessage";
import {
  deleteDataRequest,
  IDataDeletionReason,
  useDataDeletionReasonList,
} from "../../api";
import ExternalHeader from "../header/externalHeader";
import Loading from "../loading/loading";

interface IDataDeletionForm {
  reason: string;
  email: string;
}

interface DataDeletionFormProps {
  setDeleteSubmitted: Dispatch<SetStateAction<boolean>>;
}

const DataDeletionForm: React.FC<DataDeletionFormProps> = ({
  setDeleteSubmitted,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<IDataDeletionForm>();
  const panelistId = useAppSelector((state) => state.auth.panelistId);
  const accessCode = useAppSelector((state) => state.auth.accessCode);
  const panelist = useAppSelector((state) => state.auth.panelist);
  const { addAlert } = useAlertContext();
  const [isDisabled, setIsDisabled] = useState(false);
  const [{ data, loading, error }] = useDataDeletionReasonList();
  const [reasons, setReasons] = useState<IDataDeletionReason[]>([]);

  useEffect(() => {
    if (!data) return;

    setReasons(data);
  }, [data]);

  const onSubmit: SubmitHandler<IDataDeletionForm> = async ({
    reason,
    email,
  }) => {
    disabledTimer(setIsDisabled);

    try {
      await deleteDataRequest(
        accessCode,
        reason,
        panelist || email,
        panelistId,
      );
      setDeleteSubmitted(true);
    } catch (e) {
      addAlert({
        message: e?.message,
        type: "error",
        timeout: 5,
      });
    }
  };

  if (loading || !data) return <Loading />;
  if (error) return <ErrorMessage />;

  return (
    <>
      <ExternalHeader title="Data Deletion Request" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="reason"
          control={control}
          rules={{ required: "Please select a reason" }}
          render={({ field }) => (
            <Select
              label="Select an reason"
              className="mb-5"
              variant="bordered"
              {...field}
              errorMessage={errors?.reason?.message}
              isInvalid={!!errors?.reason?.message}
              autoFocus
              classNames={{
                trigger: [
                  "data-[focus=true]:border-primary",
                  "data-[open=true]:border-primary",
                ],
              }}
            >
              {reasons.map((reason) => (
                <SelectItem aria-label={reason.description} key={reason.code}>
                  {reason.description}
                </SelectItem>
              ))}
            </Select>
          )}
        />
        {!panelistId && (
          <Input
            label="Email Address"
            type="text"
            autoComplete="email"
            register={register("email", {
              required: "Please enter your email address",
              pattern: {
                value: /^\S+@\S+$/i,
                message: "Please enter a valid email address",
              },
            })}
            errorMessage={errors?.email?.message}
          />
        )}
        <div className="flex items-center justify-content gap-9">
          <Button color="secondary" type="submit" isDisabled={isDisabled}>
            Submit
          </Button>
        </div>
      </form>
    </>
  );
};

export default DataDeletionForm;
