import { Dispatch, SetStateAction } from "react";

export const disabledTimer = (
  setIsDisabled: Dispatch<SetStateAction<boolean>>,
  timer = 1000,
) => {
  setIsDisabled(true);
  setTimeout(() => {
    setIsDisabled(false);
  }, timer);
};

export const dummy = null;

export const openChromeStore = () => {
  window
    .open(
      `https://chromewebstore.google.com/detail/the-focus-project/${
        process.env.EXTENSION_ID
      }`,
      "_blank",
    )
    ?.focus();
};
